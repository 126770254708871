import { EnvironmentInterface, EnvironmentTypesEnum } from './env.model';

export const environment: EnvironmentInterface = {
  // eslint-disable-next-line global-require
  appVersion: require('../../package.json').version,
  apiUrl: 'https://api.sembot.io/api/v1',
  dataUrl: 'https://api.sembot.io',
  stripePublicKey: 'pk_test_51J56QlJvqs0qeOPOK20bOMhWnrTYxNHz7p32CBBDUehDAb1tYMKt6TkC1PcgCNhKq4cfUiGNvBYyTKH8UJsS0fwT00ITIzr85Y',
  domain: 'sembot.com',
  production: true,
  trustedUsers: [1, 9, 24, 30, 37, 38, 39, 47, 57, 59, 63, 68, 69, 71, 74, 75, 77],
  type: EnvironmentTypesEnum.prod,
  pluginMode: null,
  shopifyPublic: '5897c452a0d2d7ef34a51c6562812ee6',
  shopifyName: 'shopping-feed-ads-seo-suite',
  recaptcha: {
    siteKey: '6LdqY40mAAAAAEjZGm7-TrekptayendV8mcbKeX1', // sembot.com, sembot.io
  },
  sentryDsn: 'https://583b29e9721a5f2402a965f3c4a8f962@sentry.sembot.com/5',
  sentryUrl: 'http://172.16.0.116:9000/',
};
