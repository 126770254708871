import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartComponent } from 'ng-apexcharts';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TutorialElementDirective } from '../dashboard/tutorial/tutorial-element.directive';
import { AdsCompetitorsTableComponent } from './components/ads-competitors-table/ads-competitors-table.component';
import { AnalyticsPickerComponent } from './components/analytics-picker/analytics-picker.component';
import { ApexChartComponent } from './components/apex-chart/apex-chart.component';
import { AuditComponent } from './components/audit/audit.component';
import { BadgeComponent, BadgeSetComponent } from './components/badge/badge.component';
import { BetaLabelComponent } from './components/beta-label/beta-label.component';
import { BingAccountFormComponent } from './components/bing-account-form/bing-account-form.component';
import { ButtonBrandComponent } from './components/button-brand/button-brand.component';
import { ButtonComponent } from './components/button-with-spinner/button.component';
import { ButtonsModule } from './components/buttons/buttons.module';
import { CompetitorsTableComponent } from './components/competitors-table/competitors-table.component';
import { ConnectionCardsComponent } from './components/connections/connection-cards/connection-cards.component';
import { ConnectionNewServicesAccountComponent } from './components/connections/connection-new-services-account/connection-new-services-account.component';
import { CurrencyChooserComponent } from './components/currency-chooser/currency-chooser.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DateRangeBadgeComponent } from './components/date-range-badge/date-range-badge.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { FilesPreviewPopupComponent } from './components/files-preview-popup/files-preview-popup.component';
import { FormFieldsInTextComponent } from './components/form-fields-in-text/form-fields-in-text.component';
import { FormFieldsModule } from './components/form-fields/form-fields.module';
import { GoBackArrowComponent } from './components/go-back-arrow/go-back-arrow.component';
import { InfiniteScrollModule } from './components/infinite-scroll/infinite-scroll.module';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { NavigateBackComponent } from './components/navigate-back/navigate-back.component';
import { PageWrapperComponent } from './components/page-wrapper/page-wrapper.component';
import { ResourceBannerComponent } from './components/resource-banner/resource-banner.component';
import { TimeseriesChartSelectComponent } from './components/timeseries-chart-select/timeseries-chart-select.component';
import { TipsCarouselComponent } from './components/tips-carousel/tips-carousel.component';
import { TreeListComponent } from './components/tree-list/tree-list.component';
import { ValueChooserComponent } from './components/value-chooser/value-chooser.component';
import { WelcomeScreenComponent } from './components/welcome-screen/welcome-screen.component';
import { DirectivesModule } from './directives/directives.module';
import { IsBetaFeatureDirective } from './directives/is-beta-feature.directive';
import { CUSTOM_DATE_FORMATS } from './helpers/custom-date-formats';
import { MaterialModule } from './material-module/material.module';
import { AnalyticsFiltersModalComponent } from './modals/analytics-filters-modal/analytics-filters-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ProductAiModalComponent } from './modals/product-ai-modal/product-ai-modal.component';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    AdsCompetitorsTableComponent,
    AnalyticsFiltersModalComponent,
    AnalyticsPickerComponent,
    AuditComponent,
    BadgeComponent,
    BadgeSetComponent,
    BetaLabelComponent,
    BingAccountFormComponent,
    ButtonBrandComponent,
    ButtonComponent,
    CompetitorsTableComponent,
    ConfirmModalComponent,
    ConnectionCardsComponent,
    ConnectionNewServicesAccountComponent,
    CurrencyChooserComponent,
    DataTableComponent,
    DateRangeBadgeComponent,
    DateRangePickerComponent,
    FilesPreviewPopupComponent,
    FormFieldsInTextComponent,
    GoBackArrowComponent,
    InfoBoxComponent,
    InfoDialogComponent,
    NavigateBackComponent,
    PageWrapperComponent,
    ProductAiModalComponent,
    TimeseriesChartSelectComponent,
    TipsCarouselComponent,
    TreeListComponent,
    TutorialElementDirective,
    ValueChooserComponent,
    WelcomeScreenComponent,
  ],
  exports: [
    AdsCompetitorsTableComponent,
    AnalyticsPickerComponent,
    AuditComponent,
    BadgeComponent,
    BadgeSetComponent,
    BetaLabelComponent,
    BingAccountFormComponent,
    ButtonBrandComponent,
    ButtonComponent,
    ButtonsModule,
    ClipboardModule,
    CommonModule,
    CompetitorsTableComponent,
    ConnectionCardsComponent,
    ConnectionNewServicesAccountComponent,
    CurrencyChooserComponent,
    DataTableComponent,
    DateRangePickerComponent,
    DirectivesModule,
    FilesPreviewPopupComponent,
    FormFieldsInTextComponent,
    FormFieldsModule,
    FormsModule,
    GoBackArrowComponent,
    InfiniteScrollModule,
    InfoBoxComponent,
    InfoDialogComponent,
    NavigateBackComponent,
    NgxMatSelectSearchModule,
    NgxMatSelectSearchModule,
    PageWrapperComponent,
    PipesModule,
    ReactiveFormsModule,
    ResourceBannerComponent,
    TimeseriesChartSelectComponent,
    TipsCarouselComponent,
    TranslateModule,
    TreeListComponent,
    TutorialElementDirective,
    ValueChooserComponent,
    WelcomeScreenComponent,
    IsBetaFeatureDirective,
  ],
  imports: [
    ButtonsModule,
    ClipboardModule,
    CommonModule,
    DirectivesModule,
    FormFieldsModule,
    FormsModule,
    InfiniteScrollModule,
    MaterialModule,
    NgOptimizedImage,
    NgxMatSelectSearchModule,
    PipesModule,
    ReactiveFormsModule,
    ResourceBannerComponent,
    TranslateModule,
    ApexChartComponent,
    ChartComponent,
    IsBetaFeatureDirective,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    CookieService,
  ],
})
export class SharedModule {}
