import { OperatorEnum } from '../enums/permissions.enum';

export enum SuperiorPermissionEnum {
  superuser = 'superuser',
}

export interface RouteInterface {
  areVariable?: boolean;
  isUnavailableForWhiteLabels?: boolean;
  key?: string;
  permissions?: string[];
  permissionsOperator?: OperatorEnum;
  superiorPermission?: SuperiorPermissionEnum;
  url: string;
  urlMask?: string;

  // Additional features
  isBetaFeature?: boolean;
}

export type RouterConfigType = {
  [key in string]: RouteInterface;
};
